import isEmpty from 'lodash/isEmpty';
import { stringify } from 'qs';
import { UTM_PARAMS_SESSION_STORAGE_KEY } from './constants';

const defaultUtmKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

export function storeUtmParams(queryString) {
  if (isEmpty(queryString)) return;
  const searchParams = new URLSearchParams(queryString);
  const utmParams = Array.from(searchParams.keys())
    .filter(key => defaultUtmKeys.includes(key))
    .reduce((acc, val) => ({ ...acc, [val]: searchParams.get(val) }), {});
  if (Object.keys(utmParams).length > 0) {
    sessionStorage.setItem(
      UTM_PARAMS_SESSION_STORAGE_KEY,
      JSON.stringify(utmParams),
    );
  }
}

export function getUtmParams() {
  let params;
  try {
    params = JSON.parse(sessionStorage.getItem(UTM_PARAMS_SESSION_STORAGE_KEY));
  } catch (error) {
    params = undefined;
  }
  return params;
}

export function appendUtmParams(url, params) {
  const [baseURL, query] = url.split('?');
  const existingParams = Object.fromEntries(
    new URLSearchParams(query).entries(),
  );
  return `${baseURL}?${stringify({
    ...existingParams,
    ...params,
  })}`;
}
