const accents = {
  primary: {
    dark: '#3F0076',
  },
};

export default {
  accents,
  breakpoints: ['640px', '768px', '1024px', '1280px', '1536px'],
};
