import React, { lazy, Suspense } from 'react';
import { Spinner } from '@picter/prisma';
import { Switch, Route } from 'react-router-dom';

import paths from './paths';

const AppPage = lazy(() => import('src/pages/AppPage'));

const { APP_PATH, HOME_PATH } = paths;

const RedirectToNewGrid = () => {
  window.location.replace(process.env.REACT_APP_APP_CONTESTS_URL);
  return null;
};

const Routes = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      {/* Redirect to picter.com main app on root path */}
      <Route path={HOME_PATH} component={RedirectToNewGrid} exact />
      <Route path={APP_PATH} component={AppPage} />
    </Switch>
  </Suspense>
);

export default Routes;
