import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { AuthenticationProvider } from '@picter/cerberus-redux';
import { PrismaProvider, MaintenancePage, picterTheme } from '@picter/prisma';
import { MaintenanceCheck } from '@picter/react-app-core';

import temporaryTheme from 'src/utils/temporary-theme';
import { DevelopmentTracker, TrackerProvider } from '@picter/tracker';
import { areCookiesAccepted } from 'src/utils/tracking/gtag';
import GoogleTracker from 'src/utils/tracking/GoogleTracker';
import MixpanelProvider from 'src/utils/tracking/MixpanelProvider';

import store from './store';
import MyGrowthBookProvider from '../MyGrowthBookProvider';

const TRACKING_ENABLED_ENVIRONMENTS = [
  'review',
  'review-production',
  'staging',
  'production',
];
const isTrackingDisabled = !TRACKING_ENABLED_ENVIRONMENTS.includes(
  process.env.REACT_APP_ENV,
);

const AppProvider = ({ children }) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(areCookiesAccepted());
  useEffect(() => {
    let interval;
    if (!areCookiesAccepted()) {
      interval = setInterval(() => {
        if (areCookiesAccepted()) {
          setCookiesAccepted(true);
          clearInterval(interval);
        }
      }, 3000);
      return () => clearInterval(interval);
    }
    return () => undefined;
  }, []);
  return (
    <IntlProvider locale="en">
      <Provider store={store}>
        <PrismaProvider theme={[picterTheme, temporaryTheme]}>
          <MyGrowthBookProvider>
            <MaintenanceCheck
              errorPage={<MaintenancePage />}
              apiUrl={process.env.REACT_APP_API_URL}
            >
              <DragDropContextProvider backend={HTML5Backend}>
                <AuthenticationProvider
                  endpointHost={process.env.REACT_APP_API_URL}
                >
                  <MixpanelProvider enabled={cookiesAccepted}>
                    <TrackerProvider
                      // re-initialize when cookie-acceptance changed
                      key={`tracker-${cookiesAccepted}`}
                      tracker={
                        isTrackingDisabled || !cookiesAccepted
                          ? DevelopmentTracker
                          : GoogleTracker
                      }
                      options={{
                        debug: false,
                      }}
                    >
                      {children}
                    </TrackerProvider>
                  </MixpanelProvider>
                </AuthenticationProvider>
              </DragDropContextProvider>
            </MaintenanceCheck>
          </MyGrowthBookProvider>
        </PrismaProvider>
      </Provider>
    </IntlProvider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
