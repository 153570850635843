import React, { useEffect, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PropTypes from 'prop-types';

const MixpanelContext = React.createContext();

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || 'xxx', {
  api_host: 'https://api-eu.mixpanel.com',
  opt_out_tracking_by_default: true,
  debug: process.env.REACT_APP_ENV === 'development',
});

function MixpanelProvider({ children, enabled, userId }) {
  useEffect(() => {
    // Only track logged in users
    if (enabled && userId) {
      mixpanel.opt_in_tracking();
      mixpanel.identify(userId);
    }
  }, [enabled, userId]);

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  );
}

MixpanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userId: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
};

MixpanelProvider.defaultProps = {
  userId: undefined,
};

const mapStateToProps = state => ({
  userId: state.getIn(['user', 'id']),
});

export default compose(connect(mapStateToProps))(MixpanelProvider);

export function useMixpanel() {
  const mixpanelInstance = useContext(MixpanelContext);
  if (!mixpanelInstance) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }
  return mixpanelInstance;
}
