import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as AppPropTypes from 'src/utils/app-prop-types';
import { TrackerScope } from '@picter/tracker';
import { getPathMetaData } from 'src/routes/paths';

class PageViewTracker extends Component {
  shouldComponentUpdate({ location: newLocation }) {
    const { location: oldLocation } = this.props;
    return oldLocation.pathname !== newLocation.pathname;
  }

  render() {
    const { location } = this.props;
    const { isExact, track, ...metaData } = getPathMetaData(location.pathname);
    return track ? (
      <TrackerScope>
        {({ page }) => {
          page([metaData.scope, metaData.name].join(' / '), metaData);
          return null;
        }}
      </TrackerScope>
    ) : null;
  }
}

PageViewTracker.propTypes = {
  location: AppPropTypes.locationShape.isRequired,
};

export default withRouter(PageViewTracker);
