import React, { useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import Cookies from 'js-cookie';
import useSWR from 'swr';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useRouteMatch } from 'react-router-dom';
import paths from 'src/routes/paths';
import mixpanel from 'mixpanel-browser';

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    mixpanel.track('all:experiment_started', {
      experiment_id: experiment.key,
      variant_id: result.variationId,
      $source: 'growthbook',
    });
  },
});

async function fetcher() {
  const res = await fetch(process.env.REACT_APP_GROWTHBOOK_FEATURES_ENDPOINT);
  if (res.status !== 200) {
    return null;
  }
  const result = await res.json();
  return result && result.features;
}

function MyGrowthBookProvider({ children, user }) {
  const { data: featureFlags } = useSWR('feature-flags', fetcher);
  const submissionRouteMatch = useRouteMatch(paths.SUBMISSIONS_PATH);
  const callId = submissionRouteMatch
    ? submissionRouteMatch.params.callId
    : undefined;

  useEffect(() => {
    growthbook.setFeatures(featureFlags);
  }, [featureFlags]);

  useEffect(() => {
    // const userData = user ? user.get('attributes').toJS() : {};
    const userId = user && user.get('id');
    const email = user && user.get('email');
    const emailVerified = user && user.get('emailVerified');

    growthbook.setAttributes({
      id: userId,
      lastUserId: Cookies.get('picter-last-user-id'),
      employee: emailVerified && email && email.endsWith('@picter.com'),
      callId,
    });
  }, [user, callId]);
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
}

MyGrowthBookProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.instanceOf(Map),
};

MyGrowthBookProvider.defaultProps = {
  user: undefined,
};

const mapStateToProps = state => ({
  user: state.get('user'),
});

export default compose(connect(mapStateToProps))(MyGrowthBookProvider);
