import { createSelector } from 'reselect';

export const userIdSelector = state => {
  const user = state.get('user');
  return user ? user.get('id') : null;
};

export const groupProjectsByOwner = createSelector(
  submission => submission.getIn(['relationships', 'projects']),
  projects =>
    projects.groupBy(project =>
      project.getIn(['relationships', 'owner', 'id']),
    ),
);
