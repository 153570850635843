import React, { Component } from 'react';
import { Spinner } from '@picter/prisma';
import * as Sentry from '@sentry/react';

const withDataLoading = (
  action,
  { LoadingComponent = Spinner, renderComponent = false, shouldLoad } = {},
) => WrappedComponent =>
  class WithDataLoading extends Component {
    state = {
      loading: true,
      error: null,
    };

    async componentWillMount() {
      await this.loadData();
    }

    componentWillReceiveProps(nextProps) {
      if (shouldLoad && shouldLoad(this.props, nextProps)) {
        this.setState(
          {
            loading: true,
          },
          this.loadData,
        );
      }
    }

    loadData = async () => {
      try {
        await action(this.props);
        this.setState({
          loading: false,
        });
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
        this.setState({
          error: e,
          loading: false,
        });
      }
    };

    render() {
      if (!renderComponent && this.state.loading) {
        return <LoadingComponent />;
      }

      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };

export default withDataLoading;
