/* eslint-disable class-methods-use-this */
import { TrackerInterface } from '@picter/tracker';

class GoogleTracker extends TrackerInterface {
  constructor({ debug, key }) {
    super();

    this.debug = debug;
    this.key = key;
  }

  get instance() {
    const gtag = window.gtag;

    // Invoked means it is injected
    // Initialized means it is loaded
    if (gtag) {
      return gtag;
    }

    return null;
  }

  inject() {
    // a global gtag is already initialized in public/index.html
  }

  identify(userId, traits) {
    // not available in gtag
    console.log('identify', userId, traits);
  }

  load() {
    // not necessary in gtag
  }

  page(name /* , properties = {} */) {
    if (this.instance) {
      this.instance('event', 'page_view', {
        page_title: name,
        page_path: window.location.pathname,
        page_location: window.location.href,
      });
    }
    if (this.debug) {
      console.log('page', name);
    }
  }

  track(name /* , properties = {} */) {
    if (this.instance) {
      this.instance('event', name);
    }
    if (this.debug) {
      console.log('track', name);
    }
  }
}

export default GoogleTracker;
